<template>
	<v-container fluid>
		<v-row>
			<v-col
				:offset="!$vuetify.breakpoint.smAndDown ? 1 : 0"
				class="col-padding-zero"
				:cols="$vuetify.breakpoint.smAndDown ? 12 : 5">
				<v-card
					flat
					class="card-body">
					<div>
						<v-img
							class="logo-image"
							src="@/assets/logo/facia.png"></v-img>
					</div>
					<v-card-title class="heading">Login</v-card-title>
					<v-card-subtitle class="sub-heading">Enter your credentials to access your account.</v-card-subtitle>
					<v-form
						ref="loginForm"
						@submit.prevent="executeReCaptcha">
						<v-card-text>
							<v-row>
								<v-col
									v-for="(field, index) in fields"
									:key="index"
									cols="12">
									<label class="field-label">{{ field.label }}</label>
									<v-text-field
										dense
										outlined
										:placeholder="field.placeholder"
										hide-details="auto"
										:tabindex="field.label === 'Email' ? 1 : field.label === 'Password' ? 3 : 2"
										required
										v-model="formData[field['name']]"
										class="input-field"
										:type="field.type == 'email' ? 'email' : showPassword ? 'text' : 'password'"
										:rules="field.type == 'email' ? emailRules : passwordRules"
										:append-icon="field.type == 'email' ? 'mdi-email greyColor--text' : field.type == 'password' ? (showPassword ? 'mdi-eye greyColor--text' : 'mdi-eye-off greyColor--text') : ''"
										@click:append="() => field.type !== 'email' && (showPassword = !showPassword)">
									</v-text-field>
								</v-col>
							</v-row>
							<p class="text-right">
								<router-link
									class="forgot-password cursor-pointer"
									to="/forgot-password"
									>Forgot Password?</router-link
								>
							</p>
						</v-card-text>
						<v-card-actions class="action-btn">
							<v-btn
								block
								type="submit"
								depressed
								class="login-btn cursor-pointer"
								:loading="isLoading"
								>Login</v-btn
							>
						</v-card-actions>
						<v-card-subtitle class="sub-heading"
							>Having trouble?
							<span
								@click="getHelp()"
								class="forgot-password cursor-pointer"
								>Get Help</span
							></v-card-subtitle
						>
					</v-form>
				</v-card>
			</v-col>
			<v-col
				:cols="$vuetify.breakpoint.smAndDown ? 0 : 6"
				v-if="!$vuetify.breakpoint.smAndDown"
				class="col-padding-zero">
				<v-img
					src="@/assets/login/facia-login.png"
					width="98%"
					height="100vh"></v-img>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { sidebarItems } from '@/constants/global';
	import ApiService from '@/services/Api';
	import { showErrorMessage, showSimpleErrorMessage } from '@/utils/showError';
	import { mapGetters } from 'vuex';
	import { RECAPTCHA_SITE_KEY } from '@/utils/env.js';
	export default {
		name: 'Login',
		data() {
			return {
				reCAPTCHAKey: RECAPTCHA_SITE_KEY,
				showPassword: false,
				isLoading: false,
				formData: {
					email: '',
					password: '',
				},
				items: [],
				fields: [
					{
						label: 'Email',
						name: 'email',
						placeholder: 'Enter email',
						type: 'email',
					},
					{
						label: 'Password',
						name: 'password',
						placeholder: '**************',
						type: 'password',
					},
				],
				emailRules: [(v) => !!v || 'Email is required.', (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'Email must be valid.'],
				passwordRules: [(v) => !!v || 'Password is required.'],
				dashboardPermission: null,
				permissions: [],
			};
		},
		created() {
			const script = document.createElement('script');
			script.id = 'recaptchaScript';
			script.src = `https://www.google.com/recaptcha/api.js?render=${this.reCAPTCHAKey}`;
			script.async = true;
			script.defer = true;

			document.head.appendChild(script);
		},
		mounted() {
			const reCAPTCHABadge = document.querySelector('.grecaptcha-badge');
			// Check if the badge element exists
			if (reCAPTCHABadge) {
				// Set the badge's display property to 'none'
				reCAPTCHABadge.style.display = 'block';
			}
		},
		computed: {
			...mapGetters(['getPermissions', 'getUserId', 'getDocumentVerification']),
		},
		methods: {
			/**
			 * This method is used to login the user through api request to backend if user form data validated.
			 * @param object {formData}
			 */
			login(reCaptchaToken) {
				if (!this.$refs.loginForm.validate()) {
					return showSimpleErrorMessage('Please fill required fields correctly!');
				}
				this.formData.recaptcha_token = reCaptchaToken;
				this.isLoading = true;
				ApiService.PostRequest('/merchant/login', this.formData)
					.then((response) => {
						this.isLoading = false;
						const reCAPTCHABadge = document.querySelector('.grecaptcha-badge');
						if (reCAPTCHABadge) {
							reCAPTCHABadge.style.display = 'none';
						}

						const data = response.data.result.data;
						const merchantId = response.data.result.data.hash_id;
						const otp = response.data.result.data.otp_signature;
						// Store the OTP signature in Vuex
						this.$store.dispatch('setOtpSignature', otp);
						this.$store.dispatch('ID', merchantId);
						if (data['2fa']) {
							this.$router.push('/verify');
						} else {
							if (window.location.hostname === 'localhost') {
								localStorage.setItem('token', response.data.result.data.token);
							}
							this.$store.dispatch('LOGIN', data);
							this.getPermissions.forEach((allPermissions) => {
								this.permissions.push(allPermissions.name);
								localStorage.setItem('userPermissions', this.permissions);
							});
							//Set Customization
							this.$store.commit('SET_CUSTOMIZATION', data?.customizations)
							//Document Verification Allowed
							const documentVerification = data?.customizations?.document_verification?.document_verification_allowed;
							this.$store.dispatch('DOCUMENT_VERIFICATION_ALLOWED', documentVerification);
							const themeCustomization = data?.customizations?.liveness.enable_theme_customization;
							this.$store.dispatch('ENABLE_THEME_CUSTOMIZATION', themeCustomization);
							this.renderSideBar();
							this.$store.dispatch('SET_SIDEBAR_ITEM', {
								dashboard: this.dashboardPermission,
								items: this.items,
							});

							this.$store.dispatch('SET_FIRST_ROUTE', {
								to: '/dashboard',
								title: 'Dashboard',
								img: require('@/assets/icons/dashboard.svg'),
							});
							this.$router.push('/dashboard');
						}
					})
					.catch((error) => {
						this.isLoading = false;
						showErrorMessage(error);
					});
			},

			/**
			 * Executes Google reCAPTCHA to verify that the user is a human.
			 * Upon successful verification, it calls the login method with the reCAPTCHA token.
			 * If reCAPTCHA is not ready, it shows an error message.
			 */
			executeReCaptcha() {
				try {
					window.grecaptcha.ready(() => {
						window.grecaptcha.execute(this.reCAPTCHAKey, { action: 'submit' }).then((reCaptchaToken) => {
							// Handle the reCAPTCHA response
							this.login(reCaptchaToken);
						});
					});
				} catch (err) {
					err;
					showErrorMessage('Captcha not ready. Please try again or refresh the page.');
				}
			},

			/**
			 * Renders the sidebar menu based on the user's permissions.
			 * Iterates through predefined sidebar items and includes only those
			 * for which the user has the necessary permissions.
			 */
			renderSideBar() {
				this.dashboardPermission = this.hasPermission('view-dashboard-merchant');
				// const OcrAllowedUserID = this.getUserId;
				const OcrAllowedUserID = this.getDocumentVerification;
				const allowedSidebarItems = [];
				sidebarItems.forEach((category) => {
					const allowedCategoryItems = [];

					Object.values(category).forEach((items) => {
						items.forEach(([key, value]) => {
							if (Array.isArray(key)) {
								let hasPermission = false;
								key.forEach((permissionName) => {
									if (this.hasPermission(permissionName)) hasPermission = true;
								});
								if (hasPermission) allowedCategoryItems.push({ ...value });
							} else if (this.hasPermission(key))
								if (OcrAllowedUserID && OcrAllowedUserID === true) {
									allowedCategoryItems.push({ ...value });
								} else {
									if (value.title !== 'Document Verification') {
										allowedCategoryItems.push({ ...value });
									}
								}
						});
					});

					if (allowedCategoryItems.length > 0) {
						allowedSidebarItems.push({
							[Object.keys(category)[0]]: allowedCategoryItems,
						});
					}
				});
				this.items = allowedSidebarItems;
			},

			/**
			 * Opens the help/contact page in a new browser tab.
			 * Provides users with a quick way to access help or contact support.
			 */
			getHelp() {
				window.open('https://facia.ai/contact-us/', '_blank');
			},
		},
	};
</script>
<style lang="scss" scoped>
	@use '../../assets/scss/breakpoints.scss' as b;
	.container {
		height: 100%;
	}
	.col-padding-zero {
		padding: 0;
	}
	.card-body {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.logo-image {
		width: 187px;
		height: 61px;
		margin-top: 50px;
		margin-bottom: 80px;
	}
	.heading {
		color: #292b30;
		text-align: center;
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		padding: 16px;
	}
	.sub-heading {
		color: #878ea0;
		text-align: center;
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 12px;
		padding: 16px;
	}
	.field-label {
		color: #292b30;
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.input-field {
		width: 100%;
		height: 46px;
		border-radius: 6px;
		border-color: #e8ebf6;
	}
	.forgot-password {
		color: #213cc8;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-decoration-line: underline;
		padding-top: 14px;
	}
	.login-btn {
		width: 100%;
		height: 46px !important;
		display: flex;
		padding: 14px 24px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
		border-radius: 6px;
		color: #ffffff !important;
		background: #213cc8 !important;
		text-transform: capitalize;
		margin-bottom: 85px;
	}
	.theme--light.v-input {
		color: unset;
	}
	.action-btn {
		padding: 16px;
	}
</style>
